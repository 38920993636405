<template>
  <div class="cabec">
    <v-img :src="require('@/assets/bg6s.png')" />
    <row class="row">
      <v-col cols="2">
        <router-link to="/">
          <v-img :src="require('@/assets/logobranco.png')" width="100" />
        </router-link>
      </v-col>
      <v-col />
      <v-col cols="2" class="d-flex align-center justify-end mr-3">
        <router-link to="/">
          <v-btn class="ma-1" color="white" plain> HOME </v-btn>
        </router-link>
        <router-link to="/comprar">
          <v-btn class="ma-1" color="white" plain> COMPRAR </v-btn>
        </router-link>
        <router-link to="/parceiros">
          <v-btn class="ma-1" color="white" plain> PARCEIROS </v-btn>
        </router-link>
        <router-link to="/login" v-if="this.email == null">
          <v-btn class="ma-1" color="white" plain> FAZER LOGIN </v-btn>
        </router-link>

        <v-menu bottom min-width="200px" rounded offset-y v-else>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on">
              <v-avatar size="48" color="grey">
                <v-img :src="imagemLogado" />
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="grey">
                  <v-img :src="imagemLogado" />
                </v-avatar>
                <h3>{{ nomeLogado }}</h3>
                <p class="text-caption mt-1">
                  {{ emailLogado }}
                </p>
                <v-divider class="my-3"></v-divider>
                <router-link to="/clientes" v-if="admin == 'true'">
                  <v-btn depressed rounded text> Acessar Clientes </v-btn>
                </router-link>
                <router-link :to="'/edit/' + id" v-else>
                  <v-btn depressed rounded text> Alterar Dados </v-btn>
                </router-link>
                <v-divider class="my-3"></v-divider>
                <router-link to="/login">
                  <v-btn depressed rounded text> Sair </v-btn>
                </router-link>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </row>
  </div>
</template>

<script>
import { decriptar } from "../services/cripto";

export default {
  data() {
    return {
      id: "",
      email: "",
      emailLogado: "",
      nomeLogado: "",
      imagemLogado: "",
    };
  },

  mounted() {
    this.id = localStorage.getItem('di');
    this.email = localStorage.getItem("ma");
    this.emailLogado = decriptar(localStorage.getItem("ma"));
    this.nomeLogado = decriptar(localStorage.getItem("no"));
    this.admin = decriptar(localStorage.getItem("ad"));
    this.imagemLogado =
      process.env.VUE_APP_URL_IMAGES +
      "/" +
      decriptar(localStorage.getItem("im"));
  },
};
</script>

<style scoped>
.cabec {
  /* background-image: url("../assets/bg6.jpg"); */
  background-size: 100%;
  width: 100%;
  height: 90px;
}
.cabec img {
  bottom: 0;
  width: 100%;
}
.row {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 90px;
}
</style>


    // <v-toolbar>
    //   <v-row>
    //     <v-col cols="2">
    //       <v-toolbar-title>
    //         <router-link to="/">
    //           <v-img :src="require('@/assets/logo.png')" width="100" />
    //         </router-link>
    //       </v-toolbar-title>
    //     </v-col>
    //     <v-col />
    //     <v-col cols="1" class="d-flex align-center justify-end">
    //       <router-link to="/login">
    //         <v-avatar color="grey" size="50">
    //           <v-icon
    //             class="white--text"
    //             size="24px"
    //             v-if="this.emailLogado == null"
    //           >
    //             mdi-login
    //           </v-icon>

    //           <v-icon class="white--text" size="24px" v-else>
    //             mdi-logout
    //           </v-icon>
    //         </v-avatar>
    //       </router-link>
    //     </v-col>
    //   </v-row>
    // </v-toolbar>
