<template>
  <div>
    <Header />
    <EditarCadastro />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderGeral.vue"
import EditarCadastro from "../components/EditarCadastro.vue"
import Footer from "../components/Footer.vue"

export default {
  components: {
    Header,
    EditarCadastro,
    Footer
  }
}
</script>
