<template>
  <div class="mt-2 mt-16 pb-16">
    <v-row justify="center" align="center">
      <v-col align="center" sm="12" md="10" xl="8">
        <v-container>
          <h1 v-if="this.$route.fullPath == '/new'">
            Inclusão de novo cliente
          </h1>
          <h1 v-else>Alteração dos dados do cadastro</h1>
          <v-row>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.nome"
                label="Nome"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.empresa"
                label="Empresa"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.cargo"
                label="Cargo"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.avaliacao"
                label="Avaliação da Empresa"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.endereco1"
                label="Endereço 1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.google1"
                label="Link para Google Maps 1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.endereco2"
                label="Endereço 2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.google2"
                label="Link para Google Maps 2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.endereco3"
                label="Endereço 3"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.google3"
                label="Link para Google Maps 3"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonefixo1"
                label="Tel. Fixo 1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonefixo2"
                label="Tel. Fixo 2"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonefixo3"
                label="Tel. Fixo 3"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonecelular1"
                label="Tel. Celular 1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonecelular2"
                label="Tel. Celular 2"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonecelular3"
                label="Tel. Celular 3"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.whatsapp1"
                label="Whatsapp 1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.whatsapp2"
                label="Whatsapp 2"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.whatsapp3"
                label="Whatsapp 3"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.telefonenacional"
                label="Tel. Nacional (0300, 0800, etc)"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.website"
                label="Website"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.email"
                label="Email 1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.email2"
                label="Email 2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.facebook"
                label="Facebook 1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.facebook2"
                label="Facebook 2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.instagram"
                label="Instagram 1"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.instagram2"
                label="Instagram 2"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.twitter"
                label="Twitter"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.linkedin"
                label="Linkedin"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.tiktok"
                label="TikTok"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.visitavirtual"
                label="Visita Virtual"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.olx"
                label="OLX"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.youtube"
                label="Youtube"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.cardapio"
                label="Cardápio"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.ifood"
                label="IFood"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.appapple"
                label="Link App Store"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.appandroid"
                label="Link Google Play"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.pix"
                label="Chave PIX"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details="auto"
                v-model="cliente.pixcopiaecola"
                label="PIX Copia e Cola"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-textarea
                dense
                outlined
                height="150"
                v-model="cliente.texto"
                label="Texto Livre"
              ></v-textarea>
              <v-row>
                <v-col cols="4">
                  <h4>Cor do fonte</h4>
                  <h4>Texto Livre</h4>
                  <v-color-picker
                    class="ma-2"
                    mode="hexa"
                    hide-mode-switch
                    v-model="cliente.corfonte"
                  ></v-color-picker>
                </v-col>
                <v-col cols="4">
                  <h4>Tipo do fundo</h4>
                  <v-radio-group
                    v-model="cliente.tipofundo"
                    column
                  >
                    <v-radio
                      label="Fundo com imagem padrão"
                      value="P"
                    ></v-radio>
                    <v-radio
                      label="Escolher a cor de fundo"
                      value="C"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="4">
                  <h4 v-if="this.cliente.tipofundo=='C'">Cor do fundo</h4>
                  <h4 v-if="this.cliente.tipofundo=='C'">sem imagem</h4>
                  <v-color-picker
                    class="ma-2"
                    mode="hexa"
                    hide-mode-switch
                    v-model="cliente.corfundo"
                    v-if="this.cliente.tipofundo=='C'"
                  ></v-color-picker>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  v-model="cliente.login"
                  label="Login"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  v-model="cliente.senha"
                  label="Senha"
                  name="password"
                  @click:append="passwordVisibleToggle"
                  :type="passwordVisible ? 'text' : 'password'"
                  :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="3">
              <v-col cols="12">
                <v-img
                  id="imagem"
                  :src="imagembase64"
                  alt="Sem imagem"
                  width="100"
                />
              </v-col>
              <v-col cols="">
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  depressed
                  :loading="isSelecting"
                  @click="onUploadImageClick"
                >
                  <v-icon left>mdi-cloud-upload</v-icon>
                  Upload
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                />
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  depressed
                  @click="onDeleteImageClick"
                >
                  <v-icon left>mdi-trash-can</v-icon>
                  Limpar
                </v-btn>
              </v-col>
              <v-col cols="">
                <v-subheader>Tamanho da Imagem</v-subheader>
                <v-radio-group
                  v-model="cliente.estiloimagem"
                  hide-details
                >
                  <v-radio
                    class="ml-8"
                    value="3"
                    label="300x300"
                  ></v-radio>
                  <v-radio
                    class="ml-8"
                    value="5"
                    label="500x300"
                  ></v-radio>
                  <v-radio
                    class="ml-8"
                    value="7"
                    label="750x150"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-col>
            <v-col />
            <v-col v-if="admin == 'true'" cols="4" class="alignment-center">
              <v-switch
                v-model="cliente.administrador"
                inset
                :label="`${
                  cliente.administrador
                    ? 'Usuário é administrador'
                    : 'Usuário não é administrador'
                }`"
              ></v-switch>
            </v-col>
            <v-col />
          </v-row>
        </v-container>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import svcClientes from "../services/svcClientes";
import { encriptar, decriptar } from "../services/cripto";

export default {
  data() {
    return {
      color: "",
      passwordVisible: false,
      cliente: {
        id: "",
        nome: "",
        apelido: "",
        empresa: "",
        cargo: "",
        endereco1: "",
        endereco2: "",
        endereco3: "",
        pix: "",
        pixcopiaecola: "",
        telefonefixo1: "",
        telefonefixo2: "",
        telefonefixo3: "",
        telefonenacional: "",
        telefonecelular1: "",
        telefonecelular2: "",
        telefonecelular3: "",
        email: "",
        email2: "",
        whatsapp1: "",
        whatsapp2: "",
        whatsapp3: "",
        facebook: "",
        facebook2: "",
        twitter: "",
        instagram: "",
        instagram2: "",
        linkedin: "",
        tiktok: "",
        youtube: "",
        google1: "",
        google2: "",
        google3: "",
        visitavirtual: "",
        avaliacao: "",
        cardapio: "",
        ifood: "",
        website: "",
        olx: "",
        appapple: "",
        appandroid: "",
        texto: "",
        login: "",
        senha: "",
        administrador: false,
        imagem: "",
        estiloimagem: "",
        codigoconsulta: "",
        tipofundo: "P",
        corfundo: "#000000FF",
        corfonte: "#FFFFFFFF",
      },
      imagem: null,
      imagembase64: "",
      isSelecting: false,
      admin: ""
    };
  },

  methods: {
    passwordVisibleToggle() {
      this.passwordVisible = !this.passwordVisible;
    },

    pesquisa(id) {
      svcClientes
        .pesquisar(id)
        .then((resp) => {
          this.cliente = resp.data;
          this.cliente.senha = decriptar(this.cliente.senha);
          this.imagembase64 =
            process.env.VUE_APP_URL_IMAGES + "/" + this.cliente.imagem;
          if (this.cliente.estiloimagem == null || this.cliente.estiloimagem.trim() == "") {
            this.cliente.estiloimagem = "3";
          }
        })
        .catch((error) => {
          alert(error);
        });
    },

    cancel() {
      if (this.admin == "false") {
        this.$router.go();
      } else {
        this.$router.push("/clientes");
      }
    },

    save() {
      if (this.cliente.login.trim() == "") {
          alert("Login é um campo obrigatório. Insira um conteúdo válido.");
        }
      else {
        if (this.$route.fullPath == "/new") {
          // Novo Cliente
          this.cliente.senha = encriptar(this.cliente.senha);
          svcClientes
            .incluir(this.cliente)
            .then((resp) => {
              if (resp.status == 200) {
                this.cliente = resp.data;
                this.cliente.codigoconsulta = encriptar(this.cliente.id);
                svcClientes
                  .uploadimage(this.cliente.codigoconsulta, this.imagem)
                  .then((resp) => {
                    if (resp.status == 200) {
                      this.cliente.imagem = resp.data.imagem;
                    }
                    svcClientes.alterar(this.cliente);
                  })
                  .then(() => {
                    this.reposiciona();
                  });
              } else {
                alert("Erro inserindo novo cliente.");
              }
            })
            .catch((error) => {
              alert(error);
            });
          this.cliente.senha = decriptar(this.cliente.senha);
        } else {
          svcClientes
            .uploadimage(this.cliente.codigoconsulta, this.imagem)
            .then((resp) => {
              if (resp.status == 200) {
                this.cliente.imagem = resp.data.imagem;
              }
              this.cliente.senha = encriptar(this.cliente.senha);
              svcClientes
                .alterar(this.cliente)
                .then((resp) => {
                  if (resp.status != 200) {
                    alert("Erro alterando dados do cadastro.");
                  }
                })
                .then(() => {
                  this.reposiciona();
                })
                .catch((error) => {
                  alert(error);
                });
              this.cliente.senha = decriptar(this.cliente.senha);
            }
          );
        }
      }
    },

    reposiciona() {
      if (this.admin == "false") {
        alert("Dados salvos com sucesso.");
      } else {
        this.$router.push("/clientes");
      }
    },

    onUploadImageClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      this.imagem = e.target.files[0];

      var reader = new FileReader();

      const callback = () => {
        this.imagembase64 = reader.result;
      };
      reader.onload = callback;
      reader.readAsDataURL(this.imagem);
    },

    onDeleteImageClick() {
      this.imagem = null;
      this.imagembase64 = null;
      this.cliente.imagem = null;      
    },
  },

  mounted() {
    if (localStorage.getItem("ma") == null) {
      this.$router.push("/");
    }

    this.admin = decriptar(localStorage.getItem("ad"))

    if (this.admin == "false" &&
        this.$route.params.id != localStorage.getItem("di")) {
      this.$router.push("/edit/" + localStorage.getItem("di"));
    }

    if (this.$route.fullPath != "/new") {
      this.pesquisa(decriptar(this.$route.params.id))
    }
    else {
      this.cliente.estiloimagem = "3"
    }
  },
};
</script>
